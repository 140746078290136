var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-box top-content" },
    [
      _c("Header", { attrs: { title: "我的", back: "home" } }),
      _c("div", { staticClass: "property box-items" }, [
        _c("div", { staticClass: "flex-box" }, [
          _c("div", { staticClass: "incomes-box" }, [
            _c("div", { staticClass: "property-title" }, [
              _vm._v("总资产（USD）"),
            ]),
            _c("div", { staticClass: "box-num" }, [
              _vm._v(
                _vm._s(_vm._f("formatFloat")(_vm.money ? _vm.money.total : "0"))
              ),
            ]),
            _vm.money && _vm.money.termIncome != "0.00"
              ? _c("div", { staticClass: "property-rate" }, [
                  _vm._v("+" + _vm._s(_vm.money ? _vm.money.termIncome : "0")),
                ])
              : _vm._e(),
          ]),
          _c("div", [
            _c("div", { staticClass: "property-title" }, [_vm._v("港险保单")]),
            _c("div", { staticClass: "bd-box flex-box" }, [
              _c("div", [_vm._v(_vm._s(_vm.insuranceNum))]),
              _c("div", [_vm._v("份")]),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "defined-state",
            class: _vm.transition ? "active" : "",
          },
          [
            _c("div", { staticClass: "insure" }, [
              _vm._v(" 香港保险：" + _vm._s(_vm.insuranceNum) + "份保障中 "),
            ]),
            _c("div", { staticClass: "flex-box insure" }, [
              _c("div", [
                _vm._v(
                  "现金：" +
                    _vm._s(
                      _vm._f("formatFloat")(_vm.money ? _vm.money.cash : "0")
                    )
                ),
              ]),
              _c("div", [
                _vm._v(
                  "定期理财：" +
                    _vm._s(
                      _vm._f("formatFloat")(
                        _vm.money ? _vm.money.termInvestment : "0"
                      )
                    )
                ),
              ]),
            ]),
            _c("div", { staticClass: "btn-box" }, [
              _c(
                "button",
                { staticClass: "border-btn", on: { click: _vm.moneyOut } },
                [_vm._v("转出")]
              ),
              _c(
                "button",
                {
                  staticClass: "defined-btn",
                  staticStyle: { width: "100px", "border-radius": "20px" },
                  on: { click: _vm.moneyIn },
                },
                [_vm._v("充值")]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "icon-up",
            on: {
              touch: function ($event) {
                _vm.transition = !_vm.transition
              },
              click: function ($event) {
                _vm.transition = !_vm.transition
              },
            },
          },
          [
            _c("i", {
              class: !_vm.transition
                ? "el-icon-d-arrow-right"
                : "el-icon-d-arrow-left",
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "title" }, [_vm._v("我的持有")]),
      _c(
        "div",
        { staticClass: "box-items", staticStyle: { "margin-bottom": "3%" } },
        [
          _c(
            "div",
            { staticClass: "tab-box" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "定期理财", name: "financing" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "港险保单", name: "guarantee" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "tab-content" }, [
            _vm.activeName == "financing" && _vm.stagesList.length
              ? _c(
                  "div",
                  _vm._l(_vm.stagesList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "box-items",
                        on: {
                          click: function ($event) {
                            return _vm.financingDetail(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "flex-box tab-title" }, [
                          _c("div", [_vm._v(_vm._s(item.title))]),
                          _c("div", [
                            _vm._v(
                              "+" +
                                _vm._s(_vm._f("formatFloat")(item.yesterday))
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "flex-box tab-items" }, [
                          _c("div", [
                            _c("div", [_vm._v("总金额")]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm._f("formatFloat")(item.callmoney))
                              ),
                            ]),
                          ]),
                          _c("div", [
                            _c("div", [_vm._v("累计收益")]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm._f("formatFloat")(item.income))
                              ),
                            ]),
                          ]),
                          _c("div", [
                            _c("div", [_vm._v("预期年化(%)")]),
                            _c(
                              "div",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatFloat")(item.currentRate)
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.activeName == "financing" && _vm.stagesList.length == 0
              ? _c(
                  "div",
                  [
                    _c("el-empty", {
                      attrs: { description: "暂无定期理财产品" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.activeName == "guarantee" && _vm.guaranteeList.length
              ? _c(
                  "div",
                  _vm._l(_vm.guaranteeList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "box-items",
                        on: {
                          click: function ($event) {
                            return _vm.guaranteeDetail(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "flex-box tab-title" }, [
                          _c("div", [_vm._v(_vm._s(item.name))]),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm._f("filterPeriods")(item.status))
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "guarantee" }, [
                          _vm._v("保单编号：" + _vm._s(item.code) + " "),
                        ]),
                        _c("div", { staticClass: "guarantee" }, [
                          _vm._v("被保险人：" + _vm._s(item.insured)),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.activeName == "guarantee" && _vm.guaranteeList.length == 0
              ? _c(
                  "div",
                  [_c("el-empty", { attrs: { description: "暂无保单" } })],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }