<template>
  <div class="my-box top-content">
    <Header :title="'我的'" :back="'home'"/>
      <div class="property box-items">
        <div class="flex-box">
          <div class="incomes-box">
            <div class="property-title">总资产（USD）</div>
            <div class="box-num">{{money?money.total:'0'|formatFloat}}</div>
            <div v-if="money&&money.termIncome!='0.00'" class="property-rate">+{{money?money.termIncome:'0'}}</div>
          </div>
          <div>
            <div class="property-title">港险保单</div>
            <div class="bd-box flex-box">
              <div>{{insuranceNum}}</div>
              <div>份</div>
            </div>
          </div>
        </div>
        <div class="defined-state" :class="transition?'active':''">
          <div class="insure">
            香港保险：{{insuranceNum}}份保障中
          </div>
          <div class="flex-box insure">
            <div>现金：{{money?money.cash:'0'|formatFloat}}</div>
            <div>定期理财：{{money?money.termInvestment:'0'|formatFloat}}</div>
          </div>
          <div class="btn-box">
            <button class="border-btn" @click="moneyOut">转出</button>
            <button class="defined-btn" style="width:100px;border-radius:20px"  @click="moneyIn">充值</button>
          </div>
        </div>
        <div class="icon-up" @touch="transition=!transition" @click="transition=!transition">
          <i :class="!transition?'el-icon-d-arrow-right':'el-icon-d-arrow-left'"></i>
        </div>
      </div>
      <div class="title">我的持有</div>
      <div class="box-items" style="margin-bottom:3%">
        <div class="tab-box">
          <el-tabs v-model="activeName">
            <el-tab-pane label="定期理财" name="financing"></el-tab-pane>
            <el-tab-pane label="港险保单" name="guarantee"></el-tab-pane>
          </el-tabs>
      </div>
      <div class="tab-content">
        <div v-if="activeName=='financing'&&stagesList.length">
          <div class="box-items" v-for="(item,index) in stagesList" :key="index" @click="financingDetail(item)">
            <div class="flex-box tab-title">
              <div >{{item.title}}</div>
              <div>+{{item.yesterday| formatFloat}}</div>
            </div>
            <div class="flex-box tab-items">
              <div>
                <div>总金额</div>
                <div>{{item.callmoney | formatFloat}}</div>
              </div>
              <div>
                <div>累计收益</div>
                <div>{{item.income | formatFloat}}</div>
              </div>
              <div>
                <div>预期年化(%)</div>
                <div style="text-align:right">{{item.currentRate| formatFloat}}</div>
              </div>
              <!-- <div>
                <div>到期时间</div>
                <div>{{item.limiteDate}}</div>
              </div> -->
            </div>
          </div>
        </div>
        <div v-if="activeName=='financing'&&stagesList.length==0">
          <el-empty description="暂无定期理财产品"></el-empty>
        </div>
        <div v-if="activeName=='guarantee'&&guaranteeList.length">
          <div class="box-items" v-for="item in guaranteeList" :key="item.id" @click="guaranteeDetail(item)">
            <div class="flex-box tab-title">
              <div>{{item.name}}</div>
              <div>{{item.status|filterPeriods}}</div>
            </div>
            <div class="guarantee">保单编号：{{item.code}} 
              <!-- <span class="theme-color" style="position:relative;z-index:2"  @click="doCopy(item.code)"> 复制 </span> -->
            </div>
            <div class="guarantee">被保险人：{{item.insured}}</div>
          </div>
        </div>
         <div v-if="activeName=='guarantee'&&guaranteeList.length==0">
          <el-empty description="暂无保单"></el-empty>
        </div>
      </div>
      </div>
      <!-- <Footer :active="'hold'"/> -->
  </div>
</template>
<script>
import Header from '@/components/Header'
// import Footer from '@/components/Footer'
export default {
  components: {
    Header,
    // Footer
  },
  data(){
    return {
      money:null,
      transition:false,
      activeName:'financing',
      stagesList:[],
      insuranceNum:0,//保单个数
      insuranceReward:0,// 保险已返还金额
      guaranteeList:[]
    }
  },
  filters:{
    filterPeriods(type){
      const statusDay ={
        underwriting:'核保中',
        cooling:'冷静期',
        settled:'已结算'
      }
      return statusDay[type]
    }
  },
  created(){
    if(this.$route.query.type){
      this.activeName = this.$route.query.type
    }
    this.myAmount()//资产
    this.myStages()//定期理财
    this.getInsurances() // 保险
    this.$store.dispatch('transation/getUserAmount',{params:{code:'my',type:'info'}})
  },
  methods:{
    // 获取总资产
    myAmount(){
      this.$store.dispatch('transation/getUserAmount',{params:{code:'my',type:'money'}}).then(res => {
        this.money = res.money
      })
    },
    myStages(){
      this.$store.dispatch('transation/getUserAmount',{params:{code:'my',type:'termInvestments'}}).then(res => {
        this.stagesList = res.investment.investments
      })
    },
    getInsurances(){
      this.$store.dispatch('insurances/getInsurances').then(res => {
        this.insuranceReward = res.insurance.reward
        this.insuranceNum =res.insurance.num
        this.guaranteeList = res.insurance.insurances
      })
    },
    // 定期理财详情
    financingDetail(item){
      this.$router.push({path:'financing',query:{type:item.name}})
    },
    guaranteeDetail(item){
      this.$router.push({path:'guarantee',query:{id:item.id}})
    },
    moneyOut(){
      let info = JSON.parse(localStorage.getItem('info'));
      if(info.cardimg!='wait'){
        // 进入账户阶段
        this.$router.push({path:'transferout'})
      }else{
        this.$router.push({path:'idcard'})
      }
    },
    moneyIn(){
      let info = JSON.parse(localStorage.getItem('info'));
      if(info.validate!='wait'){
        // 进入账户阶段
        this.$router.push({path:'transferin'})
      }else{
        this.$router.push({path:'auth'})
      }
    },
    doCopy(id){
      let that =this
      this.$copyText(id).then(function () {
        that.$message({
          offset: 100,
          type: 'success',
          message: '复制成功',
          customClass:'message-tips'
        });
        window.event.cancelBubble= true
      }, function () {
          that.$message({
          offset: 100,
          type: 'error',
          message: '复制失败',
          customClass:'message-tips'
        });
        window.event.cancelBubble= true
      })
      
    }
    
 
  }
}
</script>
<style lang="scss" scoped>
.my-box{
  .title{
    margin-bottom: 0px;
  }
}
.property{
  // background: #ffffff;
  // padding: 5% 4%;
  margin-top:2.5%;
  margin-bottom:2.5%;
  .flex-box{
    width: 100%;
    justify-content: space-between;
    .incomes-box{
      position: relative;
      .property-rate{
        position: absolute;
        right: -1rem;
        top:1rem;
        font-size:.6rem;
        color: red;
      }
    }
    .property-title{
      font-size: 1rem;
      color: #333;
      margin-bottom:10px;
    }
  }
}
.tab-box{
  background: #ffffff;
  padding: 1% 2.5%;
}
.tab-content{
  background: #ffffff;
  padding: .5% 2.5% 3%;
  .box-items{
    margin:0% 0% 2.5% 0% !important;
  }
}
.tab-title{
  justify-content:space-between;
  align-items: center;
  margin-bottom: 1rem;
  :nth-child(1){
    // font-size: 1.rem;
    line-height: 1.3rem;
    // letter-spacing: 0.2px;
    font-weight: 600;
  }
  :nth-child(2){
    font-size: .8rem;
    line-height: 1;
  }
}
.tab-items{
  width: 100%;
  justify-content:space-between;
   div{
    :nth-child(1){
      font-size: .8rem;
      color:#666;
      margin-bottom:5px;
    }
    :nth-child(2){
      font-size: 1rem;
    }
}
}
.icon-up{
  text-align: center;
  i{
    transform: rotate(90deg);
    transition: transform .5s;
    -webkit-transition: transform .5s; /* Safari */
  }
  // i:hover{
  //   transform: rotate(270deg)
  // }
}
.defined-state{
  height: 0px;
  overflow: hidden;
  transition: height  .5s;
  -webkit-transition: height  .5s; /* Safari */
}
.defined-state.active{
  height: 130px;
}
.insure{
  font-size: .9rem;
  color: #666;
  margin:3% 0%;
}
.btn-box{
  text-align: center;
  .defined-btn,.border-btn{
    width: 60px;
    margin: 10px 15px;
    font-size: 1rem;
  }
}
.guarantee{
  font-size: .9rem;
  color: #666;
}
.bd-box{
  justify-content:flex-end !important;
  align-items: flex-end;
  :first-child{
    line-height: 1;
    font-size:1.6rem;
  }
}
.el-empty{
  .el-empty__image{
      width: 100px !important;
    }
}

</style>